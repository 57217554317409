import { Icon } from '@momentum-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { removeLinkRenderer } from '../../utils/markdown.utils'
import ApiRoute from '../ApiRoute'
import Link from '../Link'
import MissingProperty from '../MissingProperty'
import Callout from '../Callout'
import { useTranslation } from 'react-i18next'

interface IApiListItemProps {
  name: string
  description: string
  badgeType?: string
  endpoint?: string
  href: string
  isDeprecated?: boolean
}

const ApiListItem: React.FC<IApiListItemProps> = ({
  name,
  description,
  badgeType,
  endpoint,
  href,
  isDeprecated,
}) => {
  const { t } = useTranslation()
  const renderApiRoute = (): React.ReactNode => {
    return badgeType && endpoint ? (
      <div className="url-container">
        <ApiRoute
          badgeType={badgeType}
          endpoint={endpoint}
          className="url"
          isDeprecated={isDeprecated}
        />
      </div>
    ) : null
  }

  return (
    <li>
      <Link to={href} className="api-list-item-container">
        <div className="content">
          <h2>{name}</h2>
          {renderApiRoute()}
          {description ? (
            <ReactMarkdown
              className="description"
              source={description}
              renderers={removeLinkRenderer}
            />
          ) : (
            <MissingProperty name="api description" />
          )}
          {isDeprecated ? (
            <Callout
              type="caution"
              markdown={t('generalDocumentation.deprecatedApiCalloutText')}
            ></Callout>
          ) : null}
        </div>
        <span className="icon-span">
          <Icon name="arrow-right-optical_16" className="icon" />
        </span>
      </Link>
    </li>
  )
}

export default ApiListItem
