import { useCallback, useEffect, useRef, useState } from 'react'
import { ITableOfContents } from '../components/TableOfContents'
import { throttle } from '../utils/general.utils'

interface ITableOfContentsRefs {
  href: string
  element: HTMLElement
}

export const getElements = (tocList: any[]): any => {
  return tocList.map((tocItem) => {
    const element = document.getElementById(tocItem.href)
    return {
      href: tocItem.href,
      element,
    }
  })
}

const headerHeight = 80

const isPreviousHeaderStillVisible = (node: ITableOfContentsRefs): boolean => {
  const { top, height } = node.element.getBoundingClientRect()
  return top - headerHeight + height >= 0
}

export const getActiveElementId = (
  elementRefs: any,
  activeElementId: string,
  setActiveElementId: Function,
  disableScrollObserver: boolean | undefined
): (() => void | string) => {
  return (): string | void => {
    if (disableScrollObserver) {
      return ''
    }
    let active: ITableOfContentsRefs | null = null
    for (let i = 0; i < elementRefs.current.length; i++) {
      const node: ITableOfContentsRefs = elementRefs.current[i]
      const { top } = node.element.getBoundingClientRect()
      if (
        /* 
         Make active when the header is halfway up the page, but only if the previous header is no longer visible
         */
        top - headerHeight <= window.innerHeight / 2 &&
        (i >= 1
          ? !isPreviousHeaderStillVisible(elementRefs.current[i - 1])
          : true)
      ) {
        active = node
      } else {
        break
      }
    }

    if (active && activeElementId !== active.href) {
      setActiveElementId(active.href)
      return active.href
    }
  }
}

export const useScrollObserver = (
  tableOfContentsList: ITableOfContents[],
  disableScrollObserver: boolean | undefined
): string => {
  const elementRefs = useRef([])
  const [activeElementId, setActiveElementId] = useState('')

  const getActiveElementIdCallback = useCallback(
    getActiveElementId(
      elementRefs,
      activeElementId,
      setActiveElementId,
      disableScrollObserver
    ),
    [activeElementId]
  )

  useEffect(() => {
    elementRefs.current = getElements(tableOfContentsList)

    const getActiveElementThrottled = throttle(getActiveElementIdCallback, 50)
    document.addEventListener('scroll', getActiveElementThrottled)

    return (): void => {
      document.removeEventListener('scroll', getActiveElementThrottled)
    }
  }, [getActiveElementIdCallback, tableOfContentsList])

  return activeElementId
}
