import React, { useState } from 'react'
import CookieNotification from '../components/CookieNotification'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideNav from '../components/SideNav'
import { isBrowser } from '../utils/general.utils'

interface DocumentationLayoutProps {
  isApiLayout?: boolean
}

const DocumentationLayout: React.FC<DocumentationLayoutProps> = ({
  isApiLayout,
  children,
}) => {
  const [showSideNav, setShowSideNav] = useState(true)

  const handleShowSideNav = (): void => {
    setShowSideNav(!showSideNav)
  }
  return (
    <>
      {isApiLayout && <div className="try-out-background"></div>}
      <div>
        <Header handleSideNavToggle={handleShowSideNav} />
        {isBrowser() ? <CookieNotification /> : null}
        <div className="below-header-container">
          <SideNav showSideNav={showSideNav} />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default DocumentationLayout
