import { Icon, Sidebar, SidebarBody, SidebarNav } from '@momentum-ui/react'
import React from 'react'
import { IChildNavItem, useNavData } from '../../hooks/useNavData'
import Link from '../Link'

interface ISideNavProps {
  showSideNav?: boolean
}

const defaultProps: ISideNavProps = {
  showSideNav: true,
}

const SideNav: React.FC<ISideNavProps> = ({ showSideNav }) => {
  const navData = useNavData()

  const createNavLink = (
    item: IChildNavItem,
    index: number
  ): React.ReactNode => {
    return !item.isExternalLink ? (
      <Link
        className="nav-item"
        to={item.link}
        key={index}
        activeClassName="selected-nav-item"
        partiallyActive={true}
      >
        {item.name}
      </Link>
    ) : (
      <Link
        className="nav-item"
        to={item.link}
        key={index}
        rel="noopener noreferrer"
        target="_blank"
      >
        {item.name}
        <Icon name="pop-out_12" className="side-nav-pop-out-icon" />
      </Link>
    )
  }

  return (
    <>
      {showSideNav ? (
        <nav data-cy="sideNav" className="side-nav">
          <Sidebar withIcons={false} theme="white" className="scroll-container">
            <SidebarBody className="scroll-container-child">
              {navData.map((section, i) => {
                return (
                  <SidebarNav
                    title={section.name}
                    key={i}
                    className={i ? 'api-reference' : ''}
                    role="navigation"
                    aria-label={`Navigation ${section.name}`}
                  >
                    {section.children.map(createNavLink)}
                  </SidebarNav>
                )
              })}
            </SidebarBody>
          </Sidebar>
        </nav>
      ) : null}
    </>
  )
}

SideNav.defaultProps = defaultProps

export default SideNav
