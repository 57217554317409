import React from 'react'
import Badge from '../Badge'

interface IApiRouteProps {
  badgeType: string
  endpoint: string
  className?: string
  isDeprecated?: boolean
}

const ApiRoute: React.FC<IApiRouteProps> = ({
  badgeType,
  endpoint,
  className,
  isDeprecated,
}) => {
  const deprecatedClass = isDeprecated ? 'deprecated' : ''
  return (
    <>
      <Badge className={deprecatedClass} type={badgeType.toUpperCase()} />
      <div className="endpoint-wrapper">
        <span className={`${className || ''} ${deprecatedClass}`}>
          {endpoint}
        </span>
      </div>
    </>
  )
}

export default ApiRoute
