import React from 'react'
import { useTranslation } from 'react-i18next'
import { useScrollObserver } from '../../hooks/useScrollObserver'
import Link from '../Link'

export interface ITableOfContents {
  href?: string
  label: string
  level: string
}
export interface ITableOfContentsProps {
  tableOfContentsList: ITableOfContents[]
  disableScrollObserver?: boolean
}

const TableOfContents: React.FC<ITableOfContentsProps> = ({
  tableOfContentsList,
  disableScrollObserver,
}) => {
  const { t } = useTranslation()
  const tableOfContentsListWithoutHeaders = tableOfContentsList.filter(
    (tocItem) => tocItem.href
  )
  let activeElementId = useScrollObserver(
    tableOfContentsListWithoutHeaders,
    disableScrollObserver
  )
  if (!disableScrollObserver && !activeElementId) {
    activeElementId = tableOfContentsListWithoutHeaders[0]?.href as string
  }

  return (
    <section className="table-of-contents">
      <h2>{t('tableOfContents.contents')}</h2>
      <ul className="scroll-container">
        {tableOfContentsList.map((item, ind) => {
          const { href, label, level } = item
          return href ? (
            <li key={ind} className={activeElementId === href ? 'active' : ''}>
              <Link to={disableScrollObserver ? href : `#${href}`}>
                <span className={`heading-${level}`}>{label}</span>
              </Link>
            </li>
          ) : (
            <li key={ind}>
              <span className={`heading-${level}`}>{label}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default TableOfContents
