import React from 'react'
interface MissingPropertyInterface {
  name: string
  className?: string
}

const MissingProperty: React.FC<MissingPropertyInterface> = ({
  name,
  className,
}): JSX.Element => {
  const isDebugState = process.env.GATSBY_IS_DEBUG_STATE === 'true'
  const debugMessage = `API definition is missing the ${name} property.`

  const renderMsg = (): JSX.Element => {
    if (name.includes('type')) {
      return (
        <span className={`missing-property ${className || ''}`}>
          {debugMessage}
        </span>
      )
    } else {
      return (
        <p className={`missing-property ${className || ''}`}>{debugMessage}</p>
      )
    }
  }
  return <>{isDebugState ? renderMsg() : null}</>
}

export default MissingProperty
